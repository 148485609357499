import React from "react"
import "../styles/styles.scss"
import LsPageComponent from "../components/lsPageComponent"
import { Helmet } from "react-helmet"
const LSPage = () => (
  <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 06 -- Definiere Informationsklassen und ordne Informationen" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU, Informationsklassen" />
  </Helmet>
    <LsPageComponent 
      ls="6" 
      game="ls6"
      test="true"
      questionairs="false"
      motto="Eine Klassifizierung für sich"
      chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/621de51776dc100013b8287c/6231c0a0d4e9a90016f2cdc3/Chapter_1_42.zip"/>
     </div>
)

export default LSPage
